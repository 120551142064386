import React from 'react';
import { Color } from '../../../helpers/colors';


export default function FoodIconSVG(props: {color: Color, style?: React.CSSProperties}){
    return <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 100 100" fill={props.color.stringValue} style={props.style}>
    <path d="M67.089 40.208c16.848 1.524 28.771 5.277 28.771 9.673 0 5.746-20.375 10.405-45.511 10.405-25.135 0-45.511-4.659-45.511-10.405 0-4.092 11.038-7.693 26.064-9.39 0 0 .011-1.167-.376-2.276-.455-1.306-1.328-1.179-1.328-1.179C12.032 39.234.108 44.057.108 49.657c0 0-3.296 27.781 26.567 41.67 0 0 2.262 1.045 1.428 2.452-.334.565-1.911 1.046-1.75 1.964l-.002.021c0 2.81 10.513 3.574 23.48 3.574s23.814-.762 23.814-3.569c0-.008-.003-.016-.003-.021.17-.918-1.413-1.402-1.749-1.969-.833-1.407 1.43-2.452 1.43-2.452C100.519 78.681 100.215 54.52 99.945 50.3c.033-.213.054-.426.054-.643 0-5.958-13.49-11.037-32.435-13.011 0 0-.764.396-.843 1.45a4.441 4.441 0 00.368 2.112z"></path>
    <path d="M59.214 12.793c.347-4.497-2.573-8.539-6.025-11.506-2.286-1.967-5.758 1.153-3.466 3.125 3.847 3.31 5.794 7.368 3.612 12.002-1.921 4.083-5.885 6.854-7.694 11.03-3.321 7.658.02 16.78 5.389 22.938 1.951 2.24 5.395-.911 3.464-3.125-5.703-6.543-7.445-16.326-1.084-22.935 3.159-3.281 5.469-7.144 5.804-11.529zM71.368 17.786c.28-3.658-2.095-6.95-4.905-9.366-1.862-1.6-4.685.94-2.82 2.544 3.133 2.694 4.717 5.999 2.942 9.769-1.565 3.323-4.791 5.58-6.265 8.977-2.701 6.234.017 13.659 4.387 18.67 1.589 1.823 4.391-.742 2.821-2.544-4.644-5.326-6.062-13.288-.883-18.667 2.571-2.67 4.451-5.813 4.723-9.383zM43.544 14.461c.28-3.659-2.096-6.949-4.904-9.365-1.861-1.602-4.687.939-2.822 2.543 3.132 2.694 4.717 5.999 2.942 9.769-1.564 3.323-4.791 5.579-6.264 8.978-2.703 6.233.017 13.658 4.386 18.67 1.59 1.823 4.393-.743 2.821-2.544-4.642-5.326-6.062-13.288-.883-18.667 2.573-2.671 4.451-5.814 4.724-9.384z"></path>
  </svg>
}


