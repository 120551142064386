import React from "react";
import { Color } from "../../../helpers/colors";

function TipsIconSVG(props: {color: Color, style?: React.CSSProperties}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 24 24"
      fill={props.color.stringValue}
      style={props.style}
    >
      <path d="M12 3.457a.75.75 0 01-.75-.75V.75a.75.75 0 011.5 0v1.957a.75.75 0 01-.75.75zM18.571 6.179a.75.75 0 01-.53-1.281l1.384-1.384a.75.75 0 111.061 1.061l-1.384 1.384a.752.752 0 01-.531.22zM23.25 12.75h-1.957a.75.75 0 010-1.5h1.957a.75.75 0 010 1.5zM19.955 20.705a.744.744 0 01-.53-.22l-1.384-1.384a.75.75 0 111.061-1.061l1.384 1.384a.75.75 0 01-.531 1.281zM4.045 20.705a.75.75 0 01-.53-1.281l1.384-1.384a.75.75 0 111.061 1.061l-1.384 1.384a.748.748 0 01-.531.22zM2.707 12.75H.75a.75.75 0 010-1.5h1.957a.75.75 0 010 1.5zM5.429 6.179a.744.744 0 01-.53-.22L3.515 4.575a.75.75 0 111.061-1.061L5.96 4.898a.75.75 0 01-.531 1.281zM15 21v1.25c0 .96-.79 1.75-1.75 1.75h-2.5C9.91 24 9 23.36 9 21.96V21zM16.41 6.56a7.026 7.026 0 00-5.91-1.4c-2.65.55-4.8 2.71-5.35 5.36-.56 2.72.46 5.42 2.64 7.07.59.44 1 1.12 1.14 1.91v.01c.02-.01.05-.01.07-.01h6c.02 0 .03 0 .05.01v-.01c.14-.76.59-1.46 1.28-2A6.985 6.985 0 0019 12c0-2.12-.94-4.1-2.59-5.44zm-.66 5.94c-.41 0-.75-.34-.75-.75C15 10.23 13.77 9 12.25 9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75a4.26 4.26 0 014.25 4.25c0 .41-.34.75-.75.75z"></path>
      <path d="M8.93 19.5H9c-.02 0-.05 0-.07.01zM15.05 19.5v.01c-.02-.01-.03-.01-.05-.01z"></path>
    </svg>
  );
}

export default TipsIconSVG;
