

const AssetImages = {
    plusIcon: {
        green: require('./plus_green.png'),
        white: require('./plus_white.png'),
    },
    playIcon: require('./play.png'),
    pauseIcon: require('./pause.png'),
    deleteIcon: require('./trash.png'),
    editIcon: require('./edit.png'),
    warningIconColored: require('./warningColored.png'),
    warningIcon: require('./warning.png'),
    saveIcon: require('./save.png'),
    reloadIcon: require('./reload.png'),
    audioFileIcon: require('./audioFileIcon.png'),
    imageIcon: require('./imageIcon.png'),
    checkMarkIcon: {
        black: require('./checkMarkIcon_black.png'),
        white: require('./checkMarkIcon_white.png'),
    },
    xIcon: {
        white: require('./xIcon_white.png'), 
        green: require('./xIcon_green.png'),
    },
    minimizeIcon: require('./minimize.png'),
    zoomInIcon: require('./zoom-in.png'),
    logInIcon: require('./login.png'),
    continueIcon: require('./continueIcon.png'),
    backArrowIcon: require('./backArrow.png'),
    createNewAccountIcon: require('./createNewAccount.png'),
    alarmClock: require('./alarm-clock.png'),
    desertIcon: require('./desert.png'),
};

export default AssetImages;


