import React from "react";
import { Color } from "../../../helpers/colors";

export default function ContactRequestsIconSVG(props: {color: Color, style?: React.CSSProperties}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 0 512 512"
      fill={props.color.stringValue}
      style={props.style}
    >
      <path d="M448.8 0H256c-34.906 0-63.2 28.242-63.2 63.2v289.198c0 12.27 14.071 19.446 24 12l60.266-45.199h171.735C483.707 319.2 512 290.957 512 256V63.2C512 28.292 483.758 0 448.8 0zm-96.402 246.898c-8.277 0-15-6.718-15-15 0-8.277 6.723-15 15-15 8.282 0 15 6.723 15 15 0 8.282-6.718 15-15 15zm15-66.335v3.136c0 8.285-6.714 15-15 15-8.28 0-15-6.715-15-15v-16.066c0-8.285 6.72-15 15-15 13.88 0 25.168-11.29 25.168-25.168 0-13.875-11.289-25.164-25.168-25.164-13.949 0-25.164 11.281-25.164 25.148 0 8.285-6.718 15.012-15 15.012-8.285 0-15-6.711-15-14.992v-.02c0-30.508 24.696-55.148 55.149-55.148 30.437 0 55.183 24.746 55.183 55.168 0 25.219-17.011 46.543-40.168 53.094zm0 0M166.238 311.898c0 39.16-31.746 70.907-70.906 70.907-39.16 0-70.906-31.746-70.906-70.907 0-39.164 31.746-70.91 70.906-70.91 39.16 0 70.906 31.746 70.906 70.91zm0 0M95.332 382.805C42.684 382.805 0 425.488 0 478.137V497c0 8.285 6.715 15 15 15h160.668c8.281 0 15-6.715 15-15v-18.863c0-52.649-42.684-95.332-95.336-95.332zm0 0"></path>
    </svg>
  );
}


